<template>
  <div class="desktopLayoutClass">
    <v-app style="min-height: 80vh !important;">
      <div>
        <v-row justify="center">
          <v-dialog v-model="error_alert" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break:normal">
                  {{ $t("Warning_Message.Restart_App") }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" @click="closeMyMedicine">
                   {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="error_enable" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break:normal">
                  {{$t("Warning_Message.Something_Went_Wrong")}}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed
                    color="primary" @click="error_enable = false" style="background-color: #1467BF;">
                   {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div v-if="packageInfoDialog">
        <v-row justify="center">
          <v-dialog v-model="packageInfoDialog" persistent max-width="calc(100vw - 20px)">
            <v-card class="pt-6">
              <v-card-text>
                <div class="d-flex pa-0">
                  <div class="pr-2">
                    <img src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/orangeInfoIcon.svg">
                  </div>
                    <p class="text-justify">
                      <span>{{ $t('Customer.Packages.Package_Info1') }}</span>
                      <span v-if="packageExtraInfo.promotion_type === 'FLAT_OFF'"> {{ packageExtraInfo.discount }} MMK </span>
                      <span v-else>{{ packageExtraInfo.discount }}%</span>
                      <span>{{ $t('Customer.Packages.Package_Info2') }}</span>
                      <span>{{ $t('Customer.Packages.Package_Info3') }}</span>
                    </p>
                </div>

                <div>
                  <p class="ma-0" style="color: #F89035;" align="left">
                    <span v-if="packageExtraInfo.package_type === 'Specializations'">
                      {{ $t("Customer.Packages.Specializations_Category") }}
                    </span>

                    <span v-else>
                      {{ $t("Customer.Packages.Doctors_Category") }}
                    </span>
                  </p>

                  <div style="color: #333333;">
                    <ul>
                      <li align="left">{{ formattedSpecialization }}</li>
                    </ul>
                  </div>
                </div>
              </v-card-text>
              <v-card-actions class="pb-6 justify-center">
                  <v-btn depressed width="40%"
                    color="primary" @click="packageInfoDialog = false">
                    {{$t("Warning_Message.Ok")}}
                  </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
      <div class="header" style="position: sticky; top: 0; z-index: 3; background: white;" v-show="show_header">
        <div style="display: flex; flex-direction: row; width: 100%">
          <div style="width: 10%; padding-top: 6px" align="left" v-on:click="goBack()">
            <img src="https://img.icons8.com/material-outlined/20/000000/left.png"/>
          </div>
          <div style="width: 90%" align="left">
            <span style="font-weight: 600; font-size: 15pt">{{ $t("Customer.Packages.Packages") }}</span>
          </div>
        </div>
      </div>
      <div v-if="isPageLoading">
        <div class="allCardClass" v-if="packageDetails.length !== 0">
          <div class="pa-4 pb-1" v-for="packageData in packageDetails" :key="packageData._id">
            <div :class="packageData.packageStatus === 'ACTIVE' || packageData.packageStatus === 'INACTIVE' ? 'packageCardBackgroungActive':'packageCardBackgroungInactive'" @click="packageData.packageStatus === 'ACTIVE'? redirectToViewCorporatePackage(packageData._id, packageData.corporate_program_name) : ''">
              <div class="d-flex flex-column justify-space-between">
                <div class="d-flex justify-space-between pa-5 align-left">
                  <div class="corporatePackageTitle ma-0">
                    {{ packageData.corporate_program_name }}
                  </div>

                  <div class="d-flex flex-column" :class="packageData.packageStatus === 'INACTIVE' ? 'redemptionsLimitBlockInactive': 'redemptionsLimitBlockActive'">
                    <div class="redemptionValue pt-2 px-1 pb-1">
                      <span :class="(packageData.availableCount > 9999) ? 'redemptionRemainingSmall' : 'redemptionRemaining'"> {{ packageData.availableCount < 0 ? 0 : packageData.availableCount }}</span> <span class="totalRedemption">/{{ packageData.total_benefits }}</span>
                      <div class="bookingLeftText">{{  $t("Customer.Packages.Booking_Left") }}</div>
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-space-between pa-5 align-left">
                  <div class="corporateBlock d-flex align-center">
                    <div class="d-flex align-center"> <img src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/corporateIcon.svg"></div>
                    <span class="pl-2 normaltext">{{ packageData.corporate_name }}</span>
                  </div>

                  <div class="my-2 d-flex flex-column align-start expiresDiv">
                    <p class="ma-0">{{ $t('Customer.Packages.Expires') }}:</p>
                    <p class="ma-0">{{ packageData.end_date }}</p>
                  </div>
                </div>
              </div>

              <div class="bottomExpieryBlock" v-if="packageData.fup !== null">
                <!-- <span>{{packageData.fup}}</span> -->
                <p class="pb-1 pl-4 pt-1 expiresAtText"> {{  $t("Customer.Packages.Extra_Benefits") }}
                  <span v-if="packageData.fup.promotion_type === 'FLAT_OFF'" style="font-weight:600"> {{ packageData.fup.discount }} MMK Off</span>
                  <span v-if="packageData.fup.promotion_type === 'PERCENTAGE_OFF'"> {{ packageData.fup.discount }}% Off</span>
                </p>
                <div class="px-4 pt-1" v-on:click.stop="openPackageInfoDialog(packageData)">
                  <img src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/packagesInfoIcon.svg">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="pa-8 mt-5">
          <h3> {{  $t("Customer.Packages.No_Packages_Found") }} </h3>
        </div>
      </div>
      <div v-else class="pt-10">
        <v-progress-circular
        indeterminate
        color="primary">
        </v-progress-circular>
      </div>
      <!-- <bottomnavbar style="position:sticky; bottom:0px; width: 100%;margin-bottom:1vh" :pageName=pageName :isLoggedIn=currentCustomer :kbzEmployee=kbzEmployee></bottomnavbar> -->
    </v-app>
  </div>
</template>

<script>
import NavBar from "../../../views/navbar.vue";
import axios from "axios";
import bottomnavbar from "./bottomnavbar.vue";
export default {
  name: "AllPackages",
  data() {
    return {
      kbzEmployee:false,
      currentCustomer: "",
      isMobile: false,
      show_header: true,
      pageName: "PackagePage",
      error_enable: false,
      error_alert: false,
      name:[1,2,3,4,5,6,7],
      packageDetails: [],
      activePackage: [],
      expiredPackage: [],
      isPageLoading: false,
      packageInfoDialog: false,
      packageExtraInfo: {}
    };
  },
  components: {
    bottomnavbar,
    NavBar,
  },
  computed: {
    formattedSpecialization() {
      if (this.packageExtraInfo.package_type === 'Specializations') {
        this.formattedList = [];
        if(this.$i18n.locale  === 'mm') {
          this.packageExtraInfo.applicable_list.forEach((ele) => {
            this.formattedList.push(ele.specialization_name_mm);
          });
          return this.formattedList.join(', ');
        } else {
          this.packageExtraInfo.applicable_list.forEach((ele) => {
              this.formattedList.push(ele.specialization_name);
          })
          return this.formattedList.join(', ');
        }
      } else {
        this.formattedList = [];
        if(this.$i18n.locale  === 'mm') {
          this.packageExtraInfo.applicable_list.forEach((ele) => {
            this.formattedList.push(ele.doctor_name_mm);
          });
          return this.formattedList.join(', ');
        } else {
          this.packageExtraInfo.applicable_list.forEach((ele) => {
              this.formattedList.push(ele.doctor_name);
          })
          return this.formattedList.join(', ');
        }
      }
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    redirectToHomePage() {
      this.$router.push({
        name: "UserProfile",
      });
    },
    userPageViewTable() {
            if (this.currentCustomer === null) {
                var userPageViewTableBody = {
                pageName : 'packagesPage',
                typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                pageName: 'packagesPage',
                typeOfUser: 'CUSTOMER',
                token: this.currentCustomer
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
    },
    redirectToViewCorporatePackage(packageId, packageName){
      this.$router.push({
        path: "/customer/myParticularSubscription/"+packageName+"/"+packageId
      });
    },
    closeMyMedicine(){
      kbzpay.gotoFunction('native://kbz/customer/homepage/wallet');
    },
    openPackageInfoDialog(packageInfo) {
      if (packageInfo.packageStatus === 'ACTIVE') {
        this.packageExtraInfo = {
          package_type: packageInfo.fup.package_type,
          applicable_list: packageInfo.fup_applicable_list,
          promotion_type: packageInfo.fup.promotion_type,
          discount: packageInfo.fup.discount
        };
        this.packageInfoDialog = true;
      }
    }
  },
  mounted() {
    var userAgent = navigator.userAgent
    if(userAgent.includes("kbzpay")){
      this.show_header = false;
    }
    this.isMobile = this.$device.mobile;
       // document.title = "FAQ"
       if(this.$store.state.locale == "en")
        document.title = "Packages"
      else
        document.title = "အစီအစဥ်များ"

    if(this.$store.state.locale !== "")
    {
      this.$i18n.locale =  this.$store.state.locale;
    }
    if(this.$store.state.employee !== "")
    {
      this.kbzEmployee = this.$store.state.employee;
    }
    else
    {
      this.$store.dispatch("changeLocale", this.$i18n.locale);
    }
    this.currentCustomer = this.$cookies.get("customerToken");
    var access = this.$cookies.get("access");
    if (this.currentCustomer) {

      //logging page hit - start
      let x =  localStorage.getItem("currentURL")
      let pageName = "PackagesScreen";
      if( x == pageName)
        x = "";
      localStorage.setItem("currentURL", pageName)
      var pageAccessBody = {
        token: this.currentCustomer,
        pageName: pageName,
        previousPage: x ? x : "START"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL + "/logUserAPIInvocations", pageAccessBody)
      //logging page hit - end
      var customerPackagePageBody = {
        token: this.currentCustomer,
        typeOfUser: "CUSTOMER"
      };
      axios.post(process.env.VUE_APP_BACKEND_URL + "/customerPackagePage",customerPackagePageBody)
        .then((customerPackagePageResponse) => {
          this.activePackage = customerPackagePageResponse.data.subscription_data;
          this.expiredPackage = customerPackagePageResponse.data.expired_data;
          this.packageDetails = this.activePackage.concat(this.expiredPackage);
          this.isPageLoading = true;
        })
        .catch((customerPackagePageError) => {
          if (customerPackagePageError.response.status == 401) {
            this.$cookies.remove("customerToken");
            this.$cookies.remove("access");
            this.$router.push({
              name: "Login",
            });
          } else {
            if(window.xm || userAgent.includes("kbzpay"))
              this.error_alert = true;
            else
              this.error_enable = true;
          }
        });
    } else {
      if(window.xm || userAgent.includes("kbzpay"))
        this.error_alert = true;
      else
        this.error_enable = true;
    }
  },
};
</script>

<style scoped>
.header {
  display: block;
  box-shadow: 0px 2px 15px 0px #00000026;
  padding: 16px 20px !important;
  background-color: white;
}

.allCardClass{
 padding-bottom: 15%;
}
.packageCardBackgroungActive {
  background-image: url("https://s3iconimages.mymedicine.com.mm/blue_banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 160px;
  border-radius: 8px;
  position: relative;
}
.packageCardBackgroungInactive {
  background-image: url("https://s3iconimages.mymedicine.com.mm/blue_banner.jpg");
  filter: grayscale(100%);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 160px;
  border-radius: 8px;
  position: relative;
}
.bottomExpieryBlock{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
  border-radius: 0px 0px 8px 8px;
  background: #1467BF;
  display: flex;
  justify-content: space-between;
}
.redemptionsLimitBlockActive{
  background: #F89035;
  width: 82px;
  /* height: 75px; */
  position: absolute;
  top: 0;
  border-radius: 0px 0px 8px 8px;
  right: 16px
}
.redemptionsLimitBlockInactive{
  background: #F89035;
  width: 82px;
  filter: grayscale(100%);
  position: absolute;
  top: 0;
  border-radius: 0px 0px 8px 8px;
  right: 16px
}
.corporatePackageTitle{
  color: white;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  width: 70%;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.redemptionRemaining{
  color: white;
  font-size: 32px;
  font-weight: 700;
  line-height: 24px;
}

.redemptionRemainingSmall {
  color: white;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
}
.totalRedemption{
  color: white;
  font-size: 14px;
  line-height: 24px;
}
.bookingLeftText{
  color: white;
  font-size: 10px;
  line-height: 20px;
}
.corporateBlock{
  text-align: left;
  color: white;
}
.expiresAtText{
  color: white;
  text-align: left;
  font-size: 12px;
  line-height: 20px;
}

.expiresDiv{
  color: white;
  font-size: 12px;
  line-height: 20px;
}
.corporateNameText{
  vertical-align: middle;
}

.normaltext {
  font-size: 12px;
}
</style>
